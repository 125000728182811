import React, { useEffect } from 'react';
import useAuth from '../hooks/useAuth';
import useFetchUser from '../hooks/useFetchUser';
import useFetchCourse from '../hooks/useFetchCourse';
import './Profile.css';

const Profile = () => {
    const { user: authUser, loading: authLoading } = useAuth();
    const { user, loading: userLoading, error: userError } = useFetchUser(authUser?.uid);

    console.log('Debug: Profile => authLoading:', authLoading, ' userLoading:', userLoading);

    if (authLoading || userLoading) {
        console.log('Debug: Still loading user or auth. Showing "Loading..."');
        return <div>Loading...</div>;
    }
    if (userError) {
        console.error('Debug: userError =>', userError);
        return <div>Error fetching user data: {userError.message}</div>;
    }

    console.log('Debug: Fetched user =>', user);

    return (
        <div className="profile-page">
            <h2>Profile</h2>
            <div className="profile-details">
                <p>
                    <strong>Name:</strong> {user?.displayName || 'N/A'}
                </p>
                <p>
                    <strong>Email:</strong> {user?.email || 'N/A'}
                </p>
                <p>
                    <strong>Role:</strong> {user?.role || 'N/A'}
                </p>
                <p>
                    <strong>Access Expiration Date:</strong>{' '}
                    {user?.accessExpirationDate
                        ? new Date(user.accessExpirationDate._seconds * 1000).toLocaleDateString()
                        : 'N/A'}
                </p>
            </div>

            <div className="purchased-bundles">
                {/* We�ll keep the class name but treat them as "purchased courses" */}
                <h3>Purchased Courses</h3>
                {user?.purchasedCourses && user.purchasedCourses.length > 0 ? (
                    user.purchasedCourses.map((courseId, index) => (
                        <CourseDetailsItem key={index} courseId={courseId} />
                    ))
                ) : (
                    <p>No courses purchased yet.</p>
                )}
            </div>
        </div>
    );
};

/**
 * Fetch the course by ID, then display name, description, and endDate as "Expiration Date."
 */
const CourseDetailsItem = ({ courseId }) => {
    // Debug logging
    console.log('Debug: CourseDetailsItem => courseId:', courseId);

    // Suppose your custom hook fetches course data by ID
    const { course, loading: courseLoading, error: courseError } = useFetchCourse(courseId);

    useEffect(() => {
        if (course) {
            console.log(`Debug: Course loaded => Name: ${course.name}`);
        }
    }, [course]);

    if (courseLoading) {
        console.log('Debug: courseLoading =>', courseLoading);
        return <div>Loading course details...</div>;
    }
    if (courseError) {
        console.error('Debug: courseError =>', courseError);
        return <div>Error fetching course details: {courseError.message}</div>;
    }
    if (!course) {
        console.log('Debug: course is null or undefined');
        return <div>No course data available.</div>;
    }

    // endDate from the course doc, used as expiration date
    let expirationDate = 'N/A';
    if (course.endDate?._seconds) {
        expirationDate = new Date(course.endDate._seconds * 1000).toLocaleDateString();
    }

    return (
        <div className="bundle-details">
            <p>
                <strong>Course Name:</strong> {course?.name || 'N/A'}
            </p>
            <p>
                <strong>Description:</strong> {course?.description || 'N/A'}
            </p>
            <p>
                <strong>Expiration Date:</strong> {expirationDate}
            </p>
        </div>
    );
};

export default Profile;
