import { useState, useEffect } from 'react';
import customAxios from '../customAxios';

const useFetchCourses = () => {
    const [courses, setCourses] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchCourses = async () => {
            try {
               
                const response = await customAxios.get(
                    `${process.env.REACT_APP_API_BASE_URL}/courses`,
                    {
                        params: { role: 'instructor' },
                    }
                );
                setCourses(response.data);
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchCourses();
    }, []);

    return { courses, loading, error };
};

export default useFetchCourses;
