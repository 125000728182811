// useUpdateAuthorizedEmails.js
import { useState } from 'react';
import customAxios from '../customAxios';

const useUpdateAuthorizedEmails = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  /**
   * updateAuthorizedEmails
   * @param {string} courseId
   * @param {string} userId
   * @param {string[]} authorizedEmails
   */
  const updateAuthorizedEmails = async (courseId, userId, authorizedEmails) => {
    setLoading(true);
    setError(null);

    try {
        const response = await customAxios.put(`${process.env.REACT_APP_API_BASE_URL}/courses/${courseId}/authorized-emails`, {
        userId,
        authorizedEmails,
      });

      return response.data; // e.g. { message, authorizedEmails }
    } catch (err) {
      setError(err);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  return { updateAuthorizedEmails, loading, error };
};

export default useUpdateAuthorizedEmails;
