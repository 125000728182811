import { useState, useEffect } from 'react';
import customAxios from '../customAxios';


const useFetchTemplates = (creatorId, userRole) => {
    const [templates, setTemplates] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        // We'll only fetch templates if we have a userRole and (we're admin or we have a creatorId)
        if (!userRole) {
            setLoading(false);
            return;
        }

        const fetchTemplates = async () => {
            try {
                let url = `${process.env.REACT_APP_API_BASE_URL}/templates`;

                // If admin and no creatorId => fetch all => ?all=true
                if (userRole === 'admin' && !creatorId) {
                    url += '?all=true';
                }
                // Otherwise if a creatorId is provided => ?userId=creatorId
                else if (creatorId) {
                    url += `?userId=${creatorId}`;
                }
                // Else if user is not admin but has no creatorId => won't fetch anything

                const response = await customAxios.get(url);
                setTemplates(response.data);
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchTemplates();
    }, [creatorId, userRole]);

    return { templates, loading, error };
};

export default useFetchTemplates;
