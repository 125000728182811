import { useState, useEffect, useCallback } from 'react';
import customAxios from '../customAxios';

/**
 * A custom hook to fetch a single course by ID.
 *
 * @param {string} courseId - The ID of the course to fetch.
 */
const useFetchCourse = (courseId) => {
    const [course, setCourse] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    /**
     * A function to manually refetch the course data (e.g., after an update).
     */
    const fetchCourse = useCallback(async () => {
        //console.log('Debug: fetchCourse called with courseId:', courseId);
        if (!courseId) {
            //console.log('Debug: No courseId, skipping fetch');
            setLoading(false);
            return;
        }

        setLoading(true);
        setError(null);

        try {
            ////console.log('Debug: Making GET request to /api/courses/', courseId);
            const response = await customAxios.get(`${process.env.REACT_APP_API_BASE_URL}/courses/${courseId}`);
            ////console.log('Debug: Response from GET /courses/:', response.data);

            setCourse(response.data); // The endpoint returns { id, ...courseData }
        } catch (err) {
            console.error('Error fetching course:', err);
            setError(err);
        } finally {
            setLoading(false);
        }
    }, [courseId]);

    // Initial fetch whenever courseId changes
    useEffect(() => {
        //console.log('Debug: useEffect triggered for courseId:', courseId);
        fetchCourse();
    }, [fetchCourse, courseId]);

    return { course, loading, error, refetch: fetchCourse };
};

export default useFetchCourse;
