// TemplateDetails.js

import React, { useState } from 'react';
import TemplateForm from './TemplateForm';
import CourseForm from './CourseForm'; // The new course form
import CourseList from './CourseList';
import './Templates.css';
import './TemplateDetails.css';

const TemplateDetails = ({ template, onClose, userId}) => {
    const [showEditForm, setShowEditForm] = useState(false);
    const [showCreateCourse, setShowCreateCourse] = useState(false);

    const [currentTemplate, setCurrentTemplate] = useState(template);

    if (!currentTemplate) {
        return (
            <div className="template-details">
                <p>No template selected.</p>
            </div>
        );
    }

    const handleEditClick = () => {
        setShowEditForm(true);
    };

    const handleCreateCourseClick = () => {
        setShowCreateCourse(true);
    };

    const handleFormSuccess = (updatedTemplate) => {
        setCurrentTemplate(updatedTemplate);
        setShowEditForm(false);
    };

    const handleCourseCreated = () => {
        alert('Course created successfully!');
        setShowCreateCourse(false);
 
    };

    return (
        <div className="template-details">
            <h2>Template Details</h2>

            {!showEditForm && !showCreateCourse && (
                <>
                    <h3>{currentTemplate.name}</h3>
                    <p>{currentTemplate.description}</p>

                    <div className="resource-section">
                        <h4>Case Studies</h4>
                        {currentTemplate.caseStudies?.length ? (
                            <ul>
                                {currentTemplate.caseStudies.map((cs) => (
                                    <li key={cs.id}>
                                        <span>
                                            {cs.name || cs.id} {cs.immediate ? '(Immediate)' : '(Delayed)'}
                                        </span>
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <p>No case studies.</p>
                        )}
                    </div>

                    <div className="resource-section">
                        <h4>ML Tools</h4>
                        {currentTemplate.mlTools?.length ? (
                            <ul>
                                {currentTemplate.mlTools.map((tool) => (
                                    <li key={tool.id}>
                                        <span>
                                            {tool.name || tool.id} {tool.immediate ? '(Immediate)' : '(Delayed)'}
                                        </span>
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <p>No ML Tools.</p>
                        )}
                    </div>

                    <div className="template-actions">
                        <button className="primary-button" onClick={handleEditClick}>
                            Edit Template
                        </button>
                        <button className="secondary-button" onClick={handleCreateCourseClick}>
                            Create Course
                        </button>
                        <button className="close-button" onClick={onClose}>
                            Close
                        </button>
                    </div>

                    <CourseList template={currentTemplate} />
                </>
            )}

            {showEditForm && (
                <TemplateForm
                    initialTemplate={currentTemplate}
                    onCancel={() => setShowEditForm(false)}
                    onFormSuccess={handleFormSuccess}
                />
            )}

            {showCreateCourse && (
                <CourseForm
                    template={currentTemplate}
                    userId={userId}
                    onCancel={() => setShowCreateCourse(false)}
                    onSuccess={handleCourseCreated}
                />
            )}
        </div>
    );
};

export default TemplateDetails;
