// pages/InstructorDashboard.js
import React, { useState, useEffect } from 'react';
import useAuth from '../hooks/useAuth';
import useFetchInstructors from '../hooks/useFetchInstructors';
import useFetchTemplates from '../hooks/useFetchTemplates';

import TemplateList from './TemplateList';
import TemplateForm from './TemplateForm';
import TemplateDetails from './TemplateDetails';
import './InstructorDashboard.css';

const InstructorDashboard = () => {
    const { user, userRole, loading: authLoading } = useAuth();
    const { instructors, loading: instructorsLoading, error: instructorsError } = useFetchInstructors();
    const [selectedCreatorId, setSelectedCreatorId] = useState(user?.uid);
    const [activeTab, setActiveTab] = useState('view');
    const [selectedTemplate, setSelectedTemplate] = useState(null);

    const { templates, loading: templatesLoading, error: templatesError } =
        useFetchTemplates(selectedCreatorId, userRole);

    // If not admin, ensure we always default to user�s own ID
    useEffect(() => {
        if (userRole !== 'admin') {
            setSelectedCreatorId(user?.uid);
        }
    }, [userRole, user?.uid]);

    const handleCreatorChange = (e) => {
        setSelectedCreatorId(e.target.value);
    };

    const handleTemplateSelect = (template) => {
        setSelectedTemplate(template);
        setActiveTab('details'); // Navigate to details view when a template is selected
    };

    const handleBackToTemplates = () => {
        setSelectedTemplate(null);
        setActiveTab('view');
    };

    if (authLoading || instructorsLoading) {
        return <div>Loading...</div>;
    }

    if (instructorsError) {
        return <div>Error loading instructors: {instructorsError.message}</div>;
    }

    return (
        <div className="instructor-dashboard">
            <h2>Instructor Dashboard</h2>

            {userRole === 'admin' && (
                <div className="bundle-creators-dropdown">
                    <label htmlFor="template-creators">Select Template Creator:</label>
                    <select
                        id="template-creators"
                        value={selectedCreatorId}
                        onChange={handleCreatorChange}
                    >
                        <option value="">All Templates</option>
                        {instructors.map((creator) => (
                            <option key={creator.id} value={creator.id}>
                                {creator.email}
                            </option>
                        ))}
                    </select>
                </div>
            )}

            <div className="tabs">
                <button
                    onClick={() => setActiveTab('view')}
                    className={activeTab === 'view' ? 'active' : ''}
                >
                    View Templates
                </button>
                <button
                    onClick={() => setActiveTab('create')}
                    className={activeTab === 'create' ? 'active' : ''}
                >
                    Create Template
                </button>
            </div>

            {activeTab === 'view' && (
                <>
                    {templatesLoading ? (
                        <div>Loading templates...</div>
                    ) : templatesError ? (
                        <div>Error loading templates: {templatesError.message}</div>
                    ) : (
                        <TemplateList
                            templates={templates}
                            onSelect={handleTemplateSelect} // Pass the select handler
                        />
                    )}
                </>
            )}

            {activeTab === 'create' && (
                <TemplateForm
                    onCancel={() => setActiveTab('view')}
                />
            )}

            {activeTab === 'details' && selectedTemplate && (
                <TemplateDetails
                    template={selectedTemplate}
                    onClose={handleBackToTemplates} // Back to templates
                />
            )}
        </div>
    );
};

export default InstructorDashboard;
