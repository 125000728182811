import React, { useState, useEffect, useRef } from 'react';
import customAxios from '../customAxios';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { storage } from '../firebase';
import './ToolkitManagement.css';

const ToolkitManagement = () => {
    const [mlKits, setMlKits] = useState([]);
    const [newMlKit, setNewMlKit] = useState({
        id: null,
        imageUrl: '',
        toolName: '',
        description: '',
        // We'll keep a single string in state that the user edits,
        // then convert to an array in saveMlKit
        shinyAppsUrls: '',
        priceAmount: '',
        isPrivate: false,
        priority: null,
    });
    const [imageFile, setImageFile] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [viewMode, setViewMode] = useState('gallery');

    const formRef = useRef(null);
    const tableRef = useRef(null);

    useEffect(() => {
        fetchMlKits();
    }, []);

    
    // Fetch existing ML Kits
    const fetchMlKits = async () => {
        try {
            const response = await customAxios.get(`${process.env.REACT_APP_API_BASE_URL}/mlKits`);
            const sortedMlKits = response.data.sort((a, b) => b.priority - a.priority);

            // Load the Stripe price to display in the UI
            const mlKitsWithPrices = await Promise.all(
                sortedMlKits.map(async (kit) => {
                    const price = await fetchPrice(kit.priceId);
                    return {
                        ...kit,
                        priceAmount: price.unit_amount ? price.unit_amount / 100 : 0,
                    };
                })
            );
            setMlKits(mlKitsWithPrices);
        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    // Helper: fetch a Stripe price by ID
    const fetchPrice = async (priceId) => {
        try {
            const response = await customAxios.get(
                `${process.env.REACT_APP_API_BASE_URL}/prices/${priceId}`
            );
            return response.data;
        } catch (error) {
            console.error(`Error fetching price with ID ${priceId}:`, error);
            return { unit_amount: 0 };
        }
    };

    // Handle local file selection for images
    const handleFileChange = (e) => {
        setImageFile(e.target.files[0]);
    };

    // Generic input change (for text, checkbox)
    const handleMlKitChange = (e) => {
        const { name, value, type, checked } = e.target;
        setNewMlKit((prev) => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    // Perform image upload (if any) and then save the ML Kit
    const handleUploadAndSaveMlKit = async () => {
        // If user is editing but no new image file is set, we can proceed with the existing imageUrl.
        if (!imageFile && !newMlKit.imageUrl) {
            setError(new Error('No image selected or existing image URL provided.'));
            return;
        }
        setUploading(true);

        if (imageFile) {
            // Upload file to Firebase Storage
            const storageRef = ref(storage, `mlkits/${imageFile.name}`);
            const uploadTask = uploadBytesResumable(storageRef, imageFile);

            uploadTask.on(
                'state_changed',
                (snapshot) => {
                    const progress =
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    setUploadProgress(progress);
                },
                (uploadError) => {
                    console.error('Upload failed:', uploadError);
                    setError(uploadError);
                    setUploading(false);
                },
                async () => {
                    try {
                        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                        await saveMlKit(downloadURL);
                    } catch (saveError) {
                        console.error('Error saving ML kit:', saveError);
                        setError(saveError);
                    } finally {
                        setUploading(false);
                    }
                }
            );
        } else {
            // If no file selected, just use the existing imageUrl
            try {
                await saveMlKit(newMlKit.imageUrl);
            } catch (saveError) {
                console.error('Error saving ML kit:', saveError);
                setError(saveError);
            } finally {
                setUploading(false);
            }
        }
    };

    // Actually save (create or update) the ML kit
    const saveMlKit = async (imageUrl) => {
        // Reset previous errors
        setError(null);

        // Basic validations:
        // 1) Price must be valid
        // 2) At least one Shiny Apps URL must be present
        const priceInCents = parseFloat(newMlKit.priceAmount) * 100;
        if (isNaN(priceInCents) || priceInCents < 0) {
            throw new Error('Invalid price amount (must be non-negative).');
        }

        // Parse the multiline shinyAppsUrls field => array
        let parsedShinyApps = [];
        if (newMlKit.shinyAppsUrls.trim()) {
            parsedShinyApps = newMlKit.shinyAppsUrls
                .split('\n')
                .map((line) => line.trim())
                .filter(Boolean);
        }

        if (parsedShinyApps.length === 0) {
            // If no valid lines => throw an error
            throw new Error('Please enter at least one Shiny App URL.');
        }

        const priority = newMlKit.priority || calculateNextPriority();

        // Build the data object
        const mlKitData = {
            ...(imageUrl && { imageUrl }),
            ...(newMlKit.toolName && { toolName: newMlKit.toolName }),
            ...(newMlKit.description && { description: newMlKit.description }),
            shinyAppsUrls: parsedShinyApps,
            priceAmount: priceInCents,
            priority,
            isPrivate: newMlKit.isPrivate ?? false,
        };

        try {
            // If editing (id exists), do PUT
            if (newMlKit.id) {
                if (Object.keys(mlKitData).length > 0) {
                    await customAxios.put(
                        `${process.env.REACT_APP_API_BASE_URL}/mlKits/${newMlKit.id}`,
                        mlKitData
                    );
                } else {
                    console.error('No fields to update');
                    return;
                }
            } else {
                // Creating a new ML kit => POST
                await customAxios.post(
                    `${process.env.REACT_APP_API_BASE_URL}/mlKits`,
                    mlKitData
                );
            }

            resetForm();
            await fetchMlKits();
            if (tableRef.current) {
                tableRef.current.scrollIntoView({ behavior: 'auto' });
            }
        } catch (saveError) {
            console.error(`Error saving ML kit: ${saveError.message}`);
            throw saveError;
        }
    };

    // Populate fields for edit
    const handleMlKitEdit = (kit) => {
        setNewMlKit({
            id: kit.id,
            imageUrl: kit.imageUrl,
            toolName: kit.toolName,
            description: kit.description,
            // Convert the stored array => multiline string
            shinyAppsUrls:
                kit.shinyAppsUrls && kit.shinyAppsUrls.length
                    ? kit.shinyAppsUrls.join('\n')
                    : '',
            priceAmount: (kit.priceAmount || 0 / 100).toString(), // kit.priceAmount is in cents in DB
            isPrivate: kit.isPrivate,
            priority: kit.priority,
        });
        setImageFile(null);
        if (formRef.current) {
            formRef.current.scrollIntoView({ behavior: 'auto' });
        }
    };

    // Delete an ML kit
    const handleMlKitDelete = async (id) => {
        try {
            await customAxios.delete(
                `${process.env.REACT_APP_API_BASE_URL}/mlKits/${id}`
            );
            setMlKits((prev) => prev.filter((kit) => kit.id !== id));
        } catch (err) {
            setError(err);
        }
    };

    // Figure out next priority if not specified
    const calculateNextPriority = () => {
        if (mlKits.length === 0) return 1;
        const highestPriority = Math.max(...mlKits.map((kit) => kit.priority || 0));
        return highestPriority + 1;
    };

    // Reset the form to defaults
    const resetForm = () => {
        setNewMlKit({
            id: null,
            imageUrl: '',
            toolName: '',
            description: '',
            shinyAppsUrls: '',
            priceAmount: '',
            isPrivate: false,
            priority: null,
        });
        setImageFile(null);
        setUploadProgress(0);
        setUploading(false);
    };

    // Cancel editing
    const handleCancelEdit = () => {
        resetForm();
        if (tableRef.current) {
            tableRef.current.scrollIntoView({ behavior: 'auto' });
        }
    };

    // Reorder logic (move up or down)
    const moveMlKitUp = (index) => {
        if (index === 0) return;
        const reorderedKits = [...mlKits];
        const [kit] = reorderedKits.splice(index, 1);
        reorderedKits.splice(index - 1, 0, kit);
        updateMlKitPriorities(reorderedKits);
    };

    const moveMlKitDown = (index) => {
        if (index === mlKits.length - 1) return;
        const reorderedKits = [...mlKits];
        const [kit] = reorderedKits.splice(index, 1);
        reorderedKits.splice(index + 1, 0, kit);
        updateMlKitPriorities(reorderedKits);
    };

    // Save updated priorities to DB
    const updateMlKitPriorities = (reorderedKits) => {
        reorderedKits.forEach((kit, idx) => {
            kit.priority = reorderedKits.length - idx;
        });
        setMlKits(reorderedKits);

        // Persist the priorities (PUT)
        reorderedKits.forEach(async (kit) => {
            try {
                await customAxios.put(
                    `${process.env.REACT_APP_API_BASE_URL}/mlKits/${kit.id}`,
                    { priority: kit.priority }
                );
            } catch (priorityErr) {
                console.error('Error updating priority:', priorityErr);
            }
        });
    };

    if (loading) return <div className="loading">Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;

    return (
        <div className="toolkit-management">
            <h2 className="toolkit-management-title" ref={formRef}>
                ML Toolkit Management
            </h2>
           
            {/* ML Kit Form */}
            <div className="mlkit-form">
                <div className="form-group">
                    <label htmlFor="imageFile">Upload Image</label>
                    <input
                        type="file"
                        id="imageFile"
                        accept="image/*"
                        onChange={handleFileChange}
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="toolName">Tool Name</label>
                    <input
                        type="text"
                        id="toolName"
                        name="toolName"
                        placeholder="Enter tool name"
                        value={newMlKit.toolName}
                        onChange={handleMlKitChange}
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="description">Description</label>
                    <textarea
                        id="description"
                        name="description"
                        placeholder="Enter description"
                        value={newMlKit.description}
                        onChange={handleMlKitChange}
                        rows={5}
                        style={{ resize: 'none' }}
                    />
                </div>

                
                <div className="form-group">
                    <label htmlFor="shinyAppsUrls">
                        Shiny Apps URLs (one per line) <span style={{ color: 'red' }}>*</span>
                    </label>
                    <textarea
                        id="shinyAppsUrls"
                        name="shinyAppsUrls"
                        placeholder="https://app1...\nhttps://app2...\n..."
                        rows={4}
                        style={{ resize: 'vertical' }}
                        value={newMlKit.shinyAppsUrls}
                        onChange={handleMlKitChange}
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="priceAmount">Base Price (USD)</label>
                    <input
                        type="text"
                        id="priceAmount"
                        name="priceAmount"
                        placeholder="Enter base price"
                        value={newMlKit.priceAmount}
                        onChange={handleMlKitChange}
                    />
                </div>

                <div className="form-group checkbox-group">
                    <label>
                        <input
                            type="checkbox"
                            name="isPrivate"
                            checked={newMlKit.isPrivate}
                            onChange={handleMlKitChange}
                        />{' '}
                        Private
                    </label>
                </div>

                {newMlKit.id ? (
                    <>
                        <button onClick={handleUploadAndSaveMlKit} disabled={uploading}>
                            {uploading
                                ? `Updating... (${uploadProgress.toFixed(0)}%)`
                                : 'Update ML Kit'}
                        </button>
                        <button onClick={handleCancelEdit} disabled={uploading}>
                            Cancel Edit
                        </button>
                    </>
                ) : (
                    <button onClick={handleUploadAndSaveMlKit} disabled={uploading}>
                        {uploading
                            ? `Uploading... (${uploadProgress.toFixed(0)}%)`
                            : 'Add ML Kit'}
                    </button>
                )}
            </div>

            {/* View Mode Toggle */}
            <div className="view-toggle">
                <button
                    onClick={() => setViewMode('gallery')}
                    className={viewMode === 'gallery' ? 'active' : ''}
                >
                    Gallery View
                </button>
                <button
                    onClick={() => setViewMode('list')}
                    className={viewMode === 'list' ? 'active' : ''}
                >
                    List View
                </button>
            </div>

            {/* Conditionally Render ML Kit Table or Gallery */}
            {viewMode === 'list' ? (
                <table className="mlkits-table" ref={tableRef}>
                    <thead>
                        <tr>
                            <th>Tool Name</th>
                            <th>Description</th>
                            <th>Price</th>
                            <th>Actions</th>
                            <th>Reorder</th>
                        </tr>
                    </thead>
                    <tbody>
                        {mlKits.map((kit, index) => (
                            <tr key={kit.id}>
                                <td>{kit.toolName}</td>
                                <td>
                                    {kit.description.length > 50
                                        ? `${kit.description.slice(0, 50)}...`
                                        : kit.description}
                                </td>
                                <td>${kit.priceAmount.toFixed(2)}</td>
                                <td className="actions-column">
                                    {/* If kit.shinyAppsUrls is an array, open the first one or handle multiple */}
                                    <button
                                        onClick={() => {
                                            if (kit.shinyAppsUrls?.length) {
                                                window.open(kit.shinyAppsUrls[0], '_blank');
                                            }
                                        }}
                                        className="open-toolkit-button"
                                    >
                                        Open Tool
                                    </button>
                                    <button onClick={() => handleMlKitEdit(kit)}>Edit</button>
                                    <button onClick={() => handleMlKitDelete(kit.id)}>Delete</button>
                                </td>
                                <td>
                                    <button onClick={() => moveMlKitUp(index)} disabled={index === 0}>
                                        Move Up
                                    </button>
                                    <button
                                        onClick={() => moveMlKitDown(index)}
                                        disabled={index === mlKits.length - 1}
                                    >
                                        Move Down
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                // Gallery View
                <div className="mlkits-gallery">
                    {mlKits.map((kit) => (
                        <div className="mlkit-item" key={kit.id}>
                            <img src={kit.imageUrl} alt={kit.toolName} />
                            <h4>{kit.toolName}</h4>
                            <p>Price: ${kit.priceAmount.toFixed(2)}</p>
                            <p>{kit.description}</p>
                            <div className="actions">
                                <button
                                    onClick={() => {
                                        if (kit.shinyAppsUrls?.length) {
                                            window.open(kit.shinyAppsUrls[0], '_blank');
                                        }
                                    }}
                                    className="open-toolkit-button"
                                >
                                    Open Tool
                                </button>
                                <button onClick={() => handleMlKitEdit(kit)}>Edit</button>
                                <button onClick={() => handleMlKitDelete(kit.id)}>Delete</button>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default ToolkitManagement;
