// hooks/useFetchInstructors.js

import { useState, useEffect } from 'react';
import customAxios from '../customAxios';

const useFetchInstructors = () => {
    const [instructors, setInstructors] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchInstructors = async () => {
            try {
                // Fetch all users
                const response = await customAxios.get(`${process.env.REACT_APP_API_BASE_URL}/users`);
                const fetchedUsers = response.data;

                // Filter out only 'instructor' and 'admin'
                const filteredInstructors = fetchedUsers.filter(
                    (user) => user.role === 'instructor' || user.role === 'admin'
                );

                // Sort: place 'admin' before 'instructor'
                filteredInstructors.sort((a, b) => {
                    if (a.role === 'admin' && b.role !== 'admin') return -1;
                    if (a.role !== 'admin' && b.role === 'admin') return 1;
                    return 0;
                });

                setInstructors(filteredInstructors);
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchInstructors();
    }, []);

    return { instructors, loading, error };
};

export default useFetchInstructors;
