import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { signInWithGoogle } from '../auth';
import ThreeStepDiagram from './ThreeStepDiagram'; // Import the ThreeStepDiagram component
import './Login.css';

function Login() {
    const [error, setError] = useState(null);
    const location = useLocation();
    const navigate = useNavigate();
    const message = location.state?.message;

    // Preserve both pathname, search (query parameters), and hash
    const from = location.state?.from
        ? `${location.state.from.pathname}${location.state.from.search || ''}${location.state.from.hash || ''}`
        : '/profile';

    const handleGoogleSignIn = async () => {
        try {
            await signInWithGoogle();
            setError(null);
            navigate(from, { replace: true });
        } catch (err) {
            setError(err.message);
        }
    };

    return (
        <div className="login-container">
            <div className="login-box">
                <h2 className="login-title">Login / Register</h2>
                {message && <p className="login-message">{message}</p>}
                {error && <p className="login-error">{error}</p>}
                <button
                    onClick={handleGoogleSignIn}
                    className="login-button"
                >
                    Sign in with Google
                </button>
            </div>

            <div className="three-step-container">
                <ThreeStepDiagram />
            </div>
        </div>
    );
}

export default Login;
