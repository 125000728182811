import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import customAxios from "../customAxios";
import useAuth from "../hooks/useAuth";
import useFetchUser from "../hooks/useFetchUser";
import { stripePromise } from "../stripe";
import { motion } from "framer-motion";
import "./BundlePurchase.css";

const BundlePurchase = () => {
    const { user: authUser, loading: authLoading } = useAuth();
    const {
        user: userDetails,
        loading: userLoading,
        error: userError,
    } = useFetchUser(authUser?.uid);

    const [courseCode, setCourseCode] = useState("");
    const [courseDetails, setCourseDetails] = useState(null);
    const [authorName, setAuthorName] = useState("");
    const [loadingCourse, setLoadingCourse] = useState(false);
    const [error, setError] = useState(null);
    const [alreadyPurchased, setAlreadyPurchased] = useState(false);
    const [useDiscount, setUseDiscount] = useState(false);
    const [discountedPrice, setDiscountedPrice] = useState(null);

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const codeFromURL = queryParams.get("code");
        if (codeFromURL && !authLoading && authUser) {
            setCourseCode(codeFromURL);
            handleCourseCodeSubmit(codeFromURL);
        }
    }, [location.search, authLoading, authUser]);

    const handleCourseCodeSubmit = async (code) => {
        setLoadingCourse(true);
        setError(null);
        setAlreadyPurchased(false);

        try {
            const response = await customAxios.get(
                `${process.env.REACT_APP_API_BASE_URL}/courses/code/${code || courseCode}`
            );
            const fetchedCourse = response.data;
            setCourseDetails(fetchedCourse);

            const purchasedCourses = userDetails?.purchasedCourses || [];
            const purchasedBundles = userDetails?.purchasedBundles || [];
            const hasPaidCourses = purchasedCourses.some(async (courseId) => {
                const courseDoc = await customAxios.get(
                    `${process.env.REACT_APP_API_BASE_URL}/courses/${courseId}`
                );
                return courseDoc.data.coursePrice > 0;
            });

            const hasDiscount = hasPaidCourses || purchasedBundles.length > 0;

            if (hasDiscount && fetchedCourse.discountPrice !== undefined) {
                setUseDiscount(true);
                setDiscountedPrice(fetchedCourse.discountPrice);
            } else {
                setUseDiscount(false);
            }

            const hasPurchased = purchasedCourses.some((c) => c === fetchedCourse.id);
            setAlreadyPurchased(hasPurchased);
        } catch (err) {
            console.error("Error fetching course details:", err);
            const errorMessage =
                err.response?.data?.message || "Invalid course code. Please try again.";
            setError(errorMessage);
            setCourseDetails(null);
        } finally {
            setLoadingCourse(false);
        }
    };

    const handlePurchaseOrRedeem = async () => {
        if (!authUser || authLoading) {
            setError("You need to be logged in to redeem or purchase a course.");
            return;
        }

        if (alreadyPurchased) {
            setError("You have already purchased this course.");
            return;
        }

        if (!courseDetails) {
            setError("No course details loaded. Please enter a code first.");
            return;
        }

        setLoadingCourse(true);

        try {
            const response = await customAxios.post(
                `${process.env.REACT_APP_API_BASE_URL}/purchase-course`,
                {
                    courseId: courseDetails.id,
                    userId: authUser.uid,
                }
            );

            const stripe = await stripePromise;
            await stripe.redirectToCheckout({ sessionId: response.data.sessionId });
        } catch (err) {
            console.error("Error processing purchase or redeem request:", err);
            const errorMessage =
                err.response?.data?.message ||
                "There was an error processing your request. Please try again.";
            setError(errorMessage);
        } finally {
            setLoadingCourse(false);
        }
    };

    if (authLoading || userLoading) return <div>Loading...</div>;
    if (userError) return <div>Error loading user data: {userError.message}</div>;

    return (
        <div className="bundle-purchase-container max-w-[1240px] mx-auto mt-[90px] px-4 py-16">
            <h1 className="text-center text-4xl font-bold mb-8 text-[#25d366]">
                Purchase or Redeem Your Course
            </h1>
            <div className="bundle-code-input-section text-center">
                <h2 className="text-2xl font-bold text-[#25d366] mb-4">
                    Enter Your Course Code
                </h2>
                <p className="text-center text-lg text-gray-600 mb-4">
                    Enter the Instructor's Course Code provided by your instructor to
                    redeem or purchase a discounted course. This will give you access to
                    the resources and ML tools included in the course.
                </p>
                <input
                    type="text"
                    placeholder="Enter course code"
                    className="bundle-code-input border-2 border-gray-300 p-2 rounded-md mb-4"
                    value={courseCode}
                    onChange={(e) => setCourseCode(e.target.value)}
                />
                <button
                    onClick={() => handleCourseCodeSubmit()}
                    className="submit-bundle-code bg-[#25d366] text-white px-4 py-2 rounded-md font-medium"
                    disabled={loadingCourse}
                >
                    {loadingCourse ? "Loading..." : "Submit"}
                </button>

                {error && <p className="text-red-500 mt-4">{error}</p>}
            </div>

            {courseDetails && (
                <div className="bundle-details mt-10 text-center">
                    <h3 className="text-xl font-bold">{courseDetails.name}</h3>
                    <p className="text-gray-600">{courseDetails.description}</p>
                    <p className="text-lg font-bold text-[#25d366]">
                        {useDiscount && discountedPrice !== null && discountedPrice !== undefined
                            ? `Discounted Price: $${Number(discountedPrice).toFixed(2)}`
                            : `Price: ${courseDetails.coursePrice === 0
                                ? "Free"
                                : `$${Number(courseDetails.coursePrice).toFixed(2)}`
                            }`}
                    </p>
                    {useDiscount && discountedPrice !== null && discountedPrice !== undefined && (
                        <p className="text-green-500 mt-4">
                            You are receiving a discounted price for being a returning customer.
                        </p>
                    )}
                    <button
                        onClick={handlePurchaseOrRedeem}
                        className="purchase-bundle bg-black text-white px-6 py-2 rounded-md font-medium mt-6"
                        disabled={loadingCourse || alreadyPurchased}
                    >
                        {loadingCourse
                            ? "Processing..."
                            : useDiscount && discountedPrice !== null && discountedPrice !== undefined
                                ? `Buy for $${Number(discountedPrice).toFixed(2)}`
                                : courseDetails.coursePrice === 0
                                    ? "Redeem"
                                    : `Buy for $${Number(courseDetails.coursePrice).toFixed(2)}`}
                    </button>
                    {alreadyPurchased && (
                        <p className="text-green-500 mt-4">
                            You have already purchased this course.
                        </p>
                    )}
                </div>
            )}
        </div>
    );
};

export default BundlePurchase;
