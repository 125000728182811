import React, { useState } from 'react';
import useFetchCourse from '../hooks/useFetchCourse';
import useUpdateCourse from '../hooks/useUpdateCourse';
import useUpdateAuthorizedEmails from '../hooks/useUpdateAuthorizedEmails';
import CourseForm from './CourseForm';
import './CourseDetails.css';

function formatFirestoreDate(date) {
    try {
        if (date?._seconds) {
            const convertedDate = new Date(date._seconds * 1000);
            return convertedDate.toLocaleDateString();
        } else if (typeof date === 'string') {
            const parsedDate = new Date(date);
            if (!isNaN(parsedDate.getTime())) return parsedDate.toLocaleDateString();
        } else if (date instanceof Date) {
            return date.toLocaleDateString();
        }
        throw new Error('Unsupported date format');
    } catch (err) {
        console.error('Error formatting date:', err.message);
        return 'Invalid Date';
    }
}

const CourseDetails = ({ courseId, onClose }) => {
    console.log('Debug: Entering CourseDetails with courseId:', courseId);

    // 1. Fetch the course by ID
    const { course, loading, error, refetch } = useFetchCourse(courseId);

    // 2. Hooks for partial updates
    const { updateCourse, loading: updateLoading, error: updateError } = useUpdateCourse();

    // 3. Hook for setting authorized emails (adding & removing)
    const {
        updateAuthorizedEmails,
        loading: authEmailsLoading,
        error: authEmailsError,
    } = useUpdateAuthorizedEmails();

    // Local UI states
    const [isEditing, setIsEditing] = useState(false);
    const [showResources, setShowResources] = useState(false); // collapsible for resource state
    const [showPreAuthSection, setShowPreAuthSection] = useState(false); // collapsible for entire pre-auth block

    const [showAddEmailsForm, setShowAddEmailsForm] = useState(false);
    const [emailText, setEmailText] = useState('');
    const [file, setFile] = useState(null);

    /**
     * Called after CourseForm updates the course
     */
    const handleFormSuccess = async (updatedCourse) => {
        console.log('Debug: handleFormSuccess fired with updatedCourse:', updatedCourse);
        alert('Course updated successfully!');
        setIsEditing(false);
        if (updatedCourse?.id) {
            await refetch();
        }
    };

    /**
     * Toggle resource active/inactive
     */
    const handleResourceToggle = async (resourceType, resourceId) => {
        console.log('Debug: Toggling resource. resourceType:', resourceType, 'resourceId:', resourceId);
        if (!course || !course.resourceState) {
            console.warn('No resourceState available, cannot toggle.');
            return;
        }
        const newResourceState = JSON.parse(JSON.stringify(course.resourceState));
        newResourceState[resourceType] = newResourceState[resourceType].map((res) =>
            res.id === resourceId ? { ...res, active: !res.active } : res
        );

        const updateData = { userId: course.userId, resourceState: newResourceState };

        console.log('Debug: Submitting new resourceState to updateCourse:', updateData);

        try {
            const updated = await updateCourse(courseId, updateData);
            if (updated) {
                console.log('Debug: Resource toggle success. Re-fetching course...');
                await refetch();
            }
        } catch (toggleErr) {
            console.error('Error toggling resource state:', toggleErr);
            alert('Failed to update resource activation state.');
        }
    };

    /**
     * For merges
     */
    const parseEmailsFromText = (text) =>
        text
            .split('\n')
            .map((line) => line.trim())
            .filter((email) => email.includes('@'));

    /**
     * Handle adding new emails (merging with existing)
     */
    const handleAddEmailsSubmit = async (e) => {
        e.preventDefault();
        if (!course) return;

        let newEmails = [];

        // If there's text
        if (emailText.trim()) {
            newEmails.push(...parseEmailsFromText(emailText));
        }

        // If there's a file
        if (file) {
            try {
                const fileText = await file.text();
                newEmails.push(...parseEmailsFromText(fileText));
            } catch (err) {
                console.error('Error reading file:', err);
                alert('Failed to read the CSV file.');
                return;
            }
        }

        // Merge with old
        const oldEmails = course.authorizedEmails || [];
        const mergedSet = new Set([...oldEmails, ...newEmails]);
        const finalAuthorizedEmails = Array.from(mergedSet);

        console.log('Debug: finalAuthorizedEmails =>', finalAuthorizedEmails);

        try {
            const responseData = await updateAuthorizedEmails(courseId, course.userId, finalAuthorizedEmails);
            if (responseData) {
                alert('Emails added successfully!');
                // reset
                setShowAddEmailsForm(false);
                setEmailText('');
                setFile(null);

                // re-fetch
                await refetch();
            }
        } catch (error) {
            console.error('Error updating authorized emails:', error);
            alert('Failed to update authorized emails.');
        }
    };

    /**
     * Remove a single email from authorizedEmails
     */
    const handleRemoveEmail = async (emailToRemove) => {
        if (!course) return;
        const oldEmails = course.authorizedEmails || [];
        const filtered = oldEmails.filter((em) => em !== emailToRemove);

        console.log('Debug: removing email:', emailToRemove, ' => new list:', filtered);

        try {
            const responseData = await updateAuthorizedEmails(courseId, course.userId, filtered);
            if (responseData) {
                alert(`Removed ${emailToRemove} successfully!`);
                await refetch();
            }
        } catch (remErr) {
            console.error('Error removing email:', remErr);
            alert('Failed to remove email.');
        }
    };

    if (loading) {
        console.log('Debug: Course is loading...');
        return <div>Loading course...</div>;
    }
    if (error) {
        console.log('Debug: Error fetching course:', error);
        return <div>Error fetching course: {error.message}</div>;
    }
    if (!course) {
        console.log('Debug: No course returned from server.');
        return <div>No course found.</div>;
    }

    const displayStartDate = formatFirestoreDate(course.startDate);
    const displayEndDate = formatFirestoreDate(course.endDate);

    return (
        <div className="course-details">
            <h2>Course Details</h2>
            {!isEditing ? (
                <>
                    <h3>{course.name}</h3>
                    <p>{course.description}</p>
                    <p>Start Date: {displayStartDate} | End Date: {displayEndDate}</p>
                    <p>Course Code: {course.courseCode}</p>
                    <p>Price: ${course.coursePrice?.toFixed(2) || '0.00'}</p>

                    {/* Collapsible resources */}
                    <button
                        className="toggle-section-btn"
                        onClick={() => setShowResources((prev) => !prev)}
                    >
                        {showResources ? 'Hide Resources' : 'Show Resources'}
                    </button>

                    {showResources && course.resourceState && (
                        <div style={{ marginTop: '10px' }}>
                            <h4>Case Studies</h4>
                            <ul>
                                {course.resourceState.caseStudies.map((cs) => (
                                    <li key={cs.id}>
                                        <strong>{cs.name}</strong> - {cs.active ? 'Active' : 'Inactive'}
                                        <button
                                            onClick={() => handleResourceToggle('caseStudies', cs.id)}
                                            className="toggle-resource-btn"
                                            disabled={updateLoading}
                                        >
                                            {cs.active ? 'Deactivate' : 'Activate'}
                                        </button>
                                    </li>
                                ))}
                            </ul>

                            <h4>ML Tools</h4>
                            <ul>
                                {course.resourceState.mlTools.map((ml) => (
                                    <li key={ml.id}>
                                        <strong>{ml.name}</strong> - {ml.active ? 'Active' : 'Inactive'}
                                        <button
                                            onClick={() => handleResourceToggle('mlTools', ml.id)}
                                            className="toggle-resource-btn"
                                            disabled={updateLoading}
                                        >
                                            {ml.active ? 'Deactivate' : 'Activate'}
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}

                    {/* Collapsible PreAuth Section */}
                    <button
                        className="toggle-section-btn"
                        onClick={() => setShowPreAuthSection((prev) => !prev)}
                    >
                        {showPreAuthSection ? 'Hide Pre-Authorization' : 'Show Pre-Authorization'}
                    </button>

                    {showPreAuthSection && (
                        <div style={{ marginTop: '10px' }}>
                            <h4>Pre-Authorized Emails</h4>
                            {course.authorizedEmails && course.authorizedEmails.length > 0 ? (
                                <div className="authorized-emails-list">
                                    {course.authorizedEmails.map((em) => (
                                        <div key={em} className="authorized-email-entry">
                                            <span>{em}</span>
                                            <button
                                                onClick={() => handleRemoveEmail(em)}
                                                className="remove-email-btn"
                                                disabled={authEmailsLoading}
                                            >
                                                Remove
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <p>No pre-authorized emails set.</p>
                            )}

                            {/* Add emails button */}
                            <button
                                onClick={() => setShowAddEmailsForm((prev) => !prev)}
                                style={{ marginTop: '10px' }}
                            >
                                {showAddEmailsForm ? 'Cancel Adding Emails' : 'Add Emails'}
                            </button>

                            {showAddEmailsForm && (
                                <form onSubmit={handleAddEmailsSubmit} className="pre-auth-form">
                                    <p>Enter one email per line OR upload a CSV file:</p>
                                    <textarea
                                        rows="4"
                                        value={emailText}
                                        onChange={(e) => setEmailText(e.target.value)}
                                        placeholder="email1@example.com
email2@example.com
..."
                                    />
                                    <div>
                                        <input
                                            type="file"
                                            accept=".csv"
                                            onChange={(e) => setFile(e.target.files[0])}
                                        />
                                    </div>
                                    <button type="submit" disabled={authEmailsLoading}>
                                        Save (Merge) Emails
                                    </button>
                                    {authEmailsError && (
                                        <p className="error-message">Error: {authEmailsError.message}</p>
                                    )}
                                </form>
                            )}
                        </div>
                    )}

                    <div className="course-actions">
                        <button
                            onClick={() => setIsEditing(true)}
                            className="edit-course-btn"
                            disabled={updateLoading}
                        >
                            Edit Course
                        </button>
                        <button onClick={onClose} className="close-button">
                            Close
                        </button>
                    </div>

                    {updateError && <p className="error-message">Error updating course: {updateError.message}</p>}
                </>
            ) : (
                <CourseForm
                    initialCourse={course}
                    onCancel={() => setIsEditing(false)}
                    onSuccess={handleFormSuccess}
                />
            )}
        </div>
    );
};

export default CourseDetails;
