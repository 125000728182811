// hooks/useCreateTemplate.js
import { useState } from 'react';
import customAxios from '../customAxios';

const useCreateTemplate = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);

    const createTemplate = async (templateData) => {
        setLoading(true);
        setError(null);
        setSuccess(false);

        try {
            // POST to /api/templates
            await customAxios.post(`${process.env.REACT_APP_API_BASE_URL}/templates`, templateData);
            setSuccess(true);
        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    return { createTemplate, loading, error, success };
};

export default useCreateTemplate;
