import React, { useState } from 'react';
import useAuth from '../hooks/useAuth'; // Hook to fetch user details
import useCreateCourse from '../hooks/useCreateCourse';
import useUpdateCourse from '../hooks/useUpdateCourse';
import './CourseForm.css';

function formatFirestoreDate(date) {
    console.log('Debug: Received date:', date);

    try {
        if (date?._seconds) {
            const firestoreDate = new Date(date._seconds * 1000);
            console.log('Debug: Converted Firestore.Timestamp to:', firestoreDate);
            return firestoreDate.toISOString().split('T')[0];
        } else if (date instanceof Date) {
            console.log('Debug: Detected JavaScript Date format:', date);
            return date.toISOString().split('T')[0];
        } else if (typeof date === 'string') {
            console.log('Debug: Detected string format:', date);
            const parsedDate = new Date(date);
            if (isNaN(parsedDate.getTime())) throw new Error('Invalid date string');
            console.log('Debug: Parsed string to Date:', parsedDate);
            return parsedDate.toISOString().split('T')[0];
        }
        throw new Error('Unsupported date format');
    } catch (error) {
        console.error('Error formatting date:', error.message);
        return '';
    }
}

function adjustDateToStartOfDay(dateString) {
    const date = new Date(dateString);
    date.setHours(0, 0, 0, 0);
    return date.toISOString();
}

function adjustDateToEndOfDay(dateString) {
    const date = new Date(dateString);
    date.setHours(23, 59, 59, 999);
    return date.toISOString();
}

const CourseForm = ({ initialCourse, onCancel, onSuccess, template }) => {
    const { user } = useAuth();

    const [courseName, setCourseName] = useState(initialCourse?.name || '');
    const [courseDescription, setCourseDescription] = useState(initialCourse?.description || '');
    const [startDate, setStartDate] = useState(
        initialCourse?.startDate ? formatFirestoreDate(initialCourse.startDate) : ''
    );
    const [endDate, setEndDate] = useState(
        initialCourse?.endDate ? formatFirestoreDate(initialCourse.endDate) : ''
    );
    const [manualPrice, setManualPrice] = useState(initialCourse?.coursePrice || '');
    const [discountPrice, setDiscountPrice] = useState(initialCourse?.discountPrice || ''); // New field

    const { createCourse, loading: createLoading } = useCreateCourse();
    const { updateCourse, loading: updateLoading } = useUpdateCourse();

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validation for discountPrice
        if (
            discountPrice !== '' &&
            (parseFloat(discountPrice) >= parseFloat(manualPrice) ||
                parseFloat(discountPrice) < 0)
        ) {
            alert('Discount price must be less than the manual price and non-negative.');
            return;
        }

        const courseData = {
            name: courseName,
            description: courseDescription,
            startDate: adjustDateToStartOfDay(startDate), // Adjusted start date
            endDate: adjustDateToEndOfDay(endDate), // Adjusted end date
            manualPrice: manualPrice !== '' ? parseFloat(manualPrice) : undefined,
            discountPrice: discountPrice !== '' ? parseFloat(discountPrice) : undefined, // Add discountPrice
            userId: user?.uid,
            templateId: template?.id,
        };

        console.log('Debug: Course data being submitted:', courseData);

        try {
            const success = initialCourse
                ? await updateCourse(initialCourse.id, courseData)
                : await createCourse(courseData);

            if (success) {
                alert(`Course ${initialCourse ? 'updated' : 'created'} successfully!`);
                if (onSuccess) onSuccess(success); // Call parent with the updated course data
            }
        } catch (error) {
            console.error('Error saving course:', error);
        }
    };

    return (
        <div className="course-form">
            <h2>{initialCourse ? 'Edit Course' : 'Create Course'}</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="courseName">Course Name:</label>
                    <input
                        id="courseName"
                        type="text"
                        value={courseName}
                        onChange={(e) => setCourseName(e.target.value)}
                        required
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="courseDescription">Description:</label>
                    <textarea
                        id="courseDescription"
                        value={courseDescription}
                        onChange={(e) => setCourseDescription(e.target.value)}
                    ></textarea>
                </div>

                <div className="form-group">
                    <label htmlFor="startDate">Start Date:</label>
                    <input
                        id="startDate"
                        type="date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        required
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="endDate">End Date:</label>
                    <input
                        id="endDate"
                        type="date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        required
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="manualPrice">Manual Price (Optional):</label>
                    <input
                        id="manualPrice"
                        type="number"
                        value={manualPrice}
                        onChange={(e) => setManualPrice(e.target.value)}
                        placeholder="Enter price in USD"
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="discountPrice">Discount Price (Optional):</label>
                    <input
                        id="discountPrice"
                        type="number"
                        value={discountPrice}
                        onChange={(e) => setDiscountPrice(e.target.value)}
                        placeholder="Enter discount price in USD"
                    />
                </div>

                <div className="form-actions">
                    <button type="submit" disabled={createLoading || updateLoading}>
                        {createLoading || updateLoading
                            ? 'Saving...'
                            : initialCourse
                                ? 'Update Course'
                                : 'Create Course'}
                    </button>
                    <button type="button" onClick={onCancel}>
                        Cancel
                    </button>
                </div>
            </form>
        </div>
    );
};

export default CourseForm;
