import { useState } from 'react';
import customAxios from '../customAxios';

const useUpdateCourse = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const updateCourse = async (courseId, updatedData) => {
        setLoading(true);
        setError(null);

        try {
            const response = await customAxios.put(`${process.env.REACT_APP_API_BASE_URL}/courses/${courseId}`, updatedData);

            if (response.status === 200) {
                return response.data.updatedData;
            }

            throw new Error(`Failed to update course: ${response.statusText}`);
        } catch (err) {
            console.error('Error updating course:', err);
            setError(err);
            return null;
        } finally {
            setLoading(false);
        }
    };

    return { updateCourse, loading, error };
};

export default useUpdateCourse;
