// TemplateForm.js

import React, { useState, useEffect } from 'react';
import useAuth from '../hooks/useAuth';
import useFetchCaseStudies from '../hooks/useFetchCaseStudies';
import useFetchMlKits from '../hooks/useFetchMlKits';
import useCreateTemplate from '../hooks/useCreateTemplate';
import useUpdateTemplate from '../hooks/useUpdateTemplate';
import './Templates.css';

const TemplateForm = ({ initialTemplate, onCancel, onFormSuccess }) => {
    const { user } = useAuth();

    // Hooks for listing resources
    const {
        caseStudies: allCaseStudies,
        loading: csLoading,
        error: csError,
    } = useFetchCaseStudies();

    const {
        mlKits: allMlTools,
        loading: mlLoading,
        error: mlError,
    } = useFetchMlKits();

    // Hooks for creation and update
    const {
        createTemplate,
        loading: createLoading,
        error: createError,
        success: createSuccess,
    } = useCreateTemplate();

    const {
        updateTemplate,
        loading: updateLoading,
        error: updateError,
        success: updateSuccess,
    } = useUpdateTemplate();

    // Distinguish create vs. edit mode
    const isEditing = !!initialTemplate;

    // Local states
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    // Selected resources: { id, name, immediate }
    const [caseStudies, setCaseStudies] = useState([]);
    const [mlTools, setMlTools] = useState([]);

    // On mount, populate if editing
    useEffect(() => {
        if (isEditing && initialTemplate) {
            setName(initialTemplate.name || '');
            setDescription(initialTemplate.description || '');
            setCaseStudies(
                (initialTemplate.caseStudies || []).map((cs) => ({
                    id: cs.id,
                    name: cs.name || '',
                    immediate: !!cs.immediate,
                }))
            );
            setMlTools(
                (initialTemplate.mlTools || []).map((tool) => ({
                    id: tool.id,
                    name: tool.name || tool.toolName || '',
                    immediate: !!tool.immediate,
                }))
            );
        }
    }, [isEditing, initialTemplate]);


    const availableCaseStudies = allCaseStudies.filter(
        (cs) => !caseStudies.some((item) => item.id === cs.id)
    );
    const availableMlTools = allMlTools.filter(
        (tool) => !mlTools.some((item) => item.id === tool.id)
    );

    // Adding from Available -> Selected
    const handleAddCaseStudy = (cs) => {
        setCaseStudies((prev) => [...prev, { id: cs.id, name: cs.name, immediate: false }]);
    };
    const handleAddMlTool = (tool) => {
        setMlTools((prev) => [...prev, { id: tool.id, name: tool.toolName, immediate: false }]);
    };

    // Removing from Selected
    const handleRemoveCaseStudy = (csId) => {
        setCaseStudies((prev) => prev.filter((item) => item.id !== csId));
    };
    const handleRemoveMlTool = (toolId) => {
        setMlTools((prev) => prev.filter((item) => item.id !== toolId));
    };

    // Toggling immediate/delayed
    const toggleCaseStudyImmediate = (csId) => {
        setCaseStudies((prev) =>
            prev.map((item) =>
                item.id === csId ? { ...item, immediate: !item.immediate } : item
            )
        );
    };
    const toggleMlToolImmediate = (toolId) => {
        setMlTools((prev) =>
            prev.map((item) =>
                item.id === toolId ? { ...item, immediate: !item.immediate } : item
            )
        );
    };

    // Submit Handler
    const handleSubmit = async () => {
        const templateData = {
            name,
            description,
            caseStudies,
            mlTools,
            createdBy: initialTemplate?.createdBy || user?.uid || 'unknown',
        };

        if (isEditing) {
            await updateTemplate(initialTemplate.id, templateData);
        } else {
            await createTemplate(templateData);
        }
    };

 
    useEffect(() => {
        // If editing and updateSuccess just turned true
        if (isEditing && updateSuccess) {
            alert('Template updated successfully');
            // Merge the updated data with the initial template to pass back
            const updatedTemplate = {
                ...initialTemplate,
                name,
                description,
                caseStudies,
                mlTools,
            };

            onFormSuccess && onFormSuccess(updatedTemplate);
        }
        // If creating and createSuccess just turned true
        if (!isEditing && createSuccess) {
            alert('Template created successfully');
            // If your hook returns an ID, you can pass it here. We'll just pretend:
            const newTemplate = {
                ...initialTemplate,
                name,
                description,
                caseStudies,
                mlTools,
                id: 'new-template-id',
            };
            onFormSuccess && onFormSuccess(newTemplate);

            // Optionally reset form
            setName('');
            setDescription('');
            setCaseStudies([]);
            setMlTools([]);
        }
        // eslint-disable-next-line
    }, [updateSuccess, createSuccess]);

    // Loading / error states
    if (csLoading || mlLoading) return <div>Loading resources...</div>;
    if (csError) return <div>Error loading case studies: {csError.message}</div>;
    if (mlError) return <div>Error loading ML kits: {mlError.message}</div>;

    return (
        <div className="template-form">
            <h3>{isEditing ? 'Edit Template' : 'Create Template'}</h3>

            <label>Template Name</label>
            <input
                type="text"
                placeholder="Template Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
            />

            <label>Description</label>
            <textarea
                placeholder="Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
            />

            {/* 2-Column Layout for case studies */}
            <div className="two-column-section">
                <div className="column available-resources">
                    <h4>Available Case Studies</h4>
                    {availableCaseStudies.length === 0 && <p>None left to add.</p>}
                    {availableCaseStudies.map((cs) => (
                        <div key={cs.id} className="resource-item">
                            <span>{cs.name}</span>
                            <button onClick={() => handleAddCaseStudy(cs)}>Add</button>
                        </div>
                    ))}
                </div>

                <div className="column selected-resources">
                    <h4>Selected Case Studies</h4>
                    {caseStudies.length === 0 && <p>None selected.</p>}
                    {caseStudies.map((cs) => (
                        <div key={cs.id} className="resource-item">
                            <span>{cs.name}</span>
                            <button
                                onClick={() => handleRemoveCaseStudy(cs.id)}
                                style={{ marginLeft: '8px' }}
                            >
                                Remove
                            </button>
                            <label style={{ marginLeft: '16px' }}>
                                Immediate?
                                <input
                                    type="checkbox"
                                    checked={cs.immediate}
                                    onChange={() => toggleCaseStudyImmediate(cs.id)}
                                    style={{ marginLeft: '4px' }}
                                />
                            </label>
                        </div>
                    ))}
                </div>
            </div>

            {/* 2-Column Layout for ML Tools */}
            <div className="two-column-section">
                <div className="column available-resources">
                    <h4>Available ML Tools</h4>
                    {availableMlTools.length === 0 && <p>None left to add.</p>}
                    {availableMlTools.map((tool) => (
                        <div key={tool.id} className="resource-item">
                            <span>{tool.toolName}</span>
                            <button onClick={() => handleAddMlTool(tool)}>Add</button>
                        </div>
                    ))}
                </div>

                <div className="column selected-resources">
                    <h4>Selected ML Tools</h4>
                    {mlTools.length === 0 && <p>None selected.</p>}
                    {mlTools.map((tool) => (
                        <div key={tool.id} className="resource-item">
                            <span>{tool.name}</span>
                            <button
                                onClick={() => handleRemoveMlTool(tool.id)}
                                style={{ marginLeft: '8px' }}
                            >
                                Remove
                            </button>
                            <label style={{ marginLeft: '16px' }}>
                                Immediate?
                                <input
                                    type="checkbox"
                                    checked={tool.immediate}
                                    onChange={() => toggleMlToolImmediate(tool.id)}
                                    style={{ marginLeft: '4px' }}
                                />
                            </label>
                        </div>
                    ))}
                </div>
            </div>

            <div style={{ marginTop: '16px' }}>
                <button
                    onClick={handleSubmit}
                    disabled={createLoading || updateLoading}
                >
                    {isEditing
                        ? updateLoading
                            ? 'Updating...'
                            : 'Update Template'
                        : createLoading
                            ? 'Saving...'
                            : 'Save Template'}
                </button>
                <button onClick={onCancel} style={{ marginLeft: '8px' }}>
                    Cancel
                </button>
            </div>

            {/* Show creation/update errors */}
            {createError && <p style={{ color: 'red' }}>Error: {createError.message}</p>}
            {updateError && <p style={{ color: 'red' }}>Error: {updateError.message}</p>}
        </div>
    );
};

export default TemplateForm;
