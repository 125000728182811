import { useState, useEffect } from 'react';
import customAxios from '../customAxios';

const useFetchCoursesByTemplate = (templateId) => {
    const [activeCourses, setActiveCourses] = useState([]);
    const [expiredCourses, setExpiredCourses] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!templateId) {
            setLoading(false);
            return;
        }

        const fetchCourses = async () => {
            try {
                const response = await customAxios.get(
                    `${process.env.REACT_APP_API_BASE_URL}/courses/template/${templateId}`
                );
                const { activeCourses, expiredCourses } = response.data;

                setActiveCourses(activeCourses);
                setExpiredCourses(expiredCourses);
            } catch (err) {
                console.error('Error fetching courses:', err); // Log the error for debugging
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchCourses();
    }, [templateId]);

    return { activeCourses, expiredCourses, loading, error };
};

export default useFetchCoursesByTemplate;
