// TemplateList.js
import React, { useState } from 'react';
import './Templates.css';
import TemplateDetails from './TemplateDetails';

const TemplateList = ({ templates }) => {
    const [selectedTemplate, setSelectedTemplate] = useState(null);

    const handleSelectTemplate = (template) => {
        setSelectedTemplate(template);
    };

    const handleCloseDetails = () => {
        setSelectedTemplate(null);
    };

    if (!templates || templates.length === 0) {
        return (
            <div className="template-list">
                <h2>Your Templates</h2>
                <p>No templates found.</p>
            </div>
        );
    }

    return (
        <div className="template-list">
            <h2>Your Templates</h2>

            {selectedTemplate ? (
                <TemplateDetails
                    template={selectedTemplate}
                    onClose={handleCloseDetails}
                />
            ) : (
                templates.map((template) => (
                    <div
                        key={template.id}
                        className="template-item"
                        onClick={() => handleSelectTemplate(template)}
                    >
                        <div className="template-info">
                            <h4>{template.name}</h4>
                            <p>{template.description}</p>
                        </div>
                    </div>
                ))
            )}
        </div>
    );
};

export default TemplateList;
