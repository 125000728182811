import React, { useState, useMemo, useRef } from 'react';
import useFetchUsers from '../hooks/useFetchUsers'; // Fetches users for the "Role View"
import useFetchCourses from '../hooks/useFetchCourses'; 
import './UserManagement.css';
import UserDetail from './UserDetail';
import { exportToCsv } from '../utils/exportToCsv'; // Utility for CSV export

const UserManagement = ({ currentUser }) => {
    // Fetching users
    const { users, loading: usersLoading, error: usersError } = useFetchUsers();

    // Fetching courses
    const {
        courses,
        loading: coursesLoading,
        error: coursesError,
    } = useFetchCourses();

    const [selectedUser, setSelectedUser] = useState(null);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
    const [filterConfig, setFilterConfig] = useState({
        email: '',
        displayName: '',
        role: '',
    });

    // State for managing view
    const [viewMode, setViewMode] = useState('role'); // 'role' or 'course'

    // Course-related state
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [currentAccessUsers, setCurrentAccessUsers] = useState([]);
    const [expiredAccessUsers, setExpiredAccessUsers] = useState([]);

    const [adminPage, setAdminPage] = useState(1);
    const [instructorPage, setInstructorPage] = useState(1);
    const [userPage, setUserPage] = useState(1);

    const [adminsPerPage, setAdminsPerPage] = useState(10);
    const [instructorsPerPage, setInstructorsPerPage] = useState(10);
    const [usersPerPage, setUsersPerPage] = useState(10);

    const userDetailRef = useRef(null);

    const filterUsersByCourse = (courseId) => {
        if (!courseId) {
            console.log("No courseId provided. Exiting filterUsersByCourse.");
            return;
        }

        console.log("Filtering users by courseId:", courseId);

        const currentUsers = [];
        const expiredUsers = [];

        users.forEach((user) => {
            if (Array.isArray(user.purchasedCourses)) {
                console.log(`User ${user.email} has purchasedCourses:`, user.purchasedCourses);

                user.purchasedCourses.forEach((c) => {
                    // c is a string (the courseId)
                    if (c === courseId) {
                        console.log(`MATCH FOUND for user ${user.email} => courseId: ${c}`);

                        currentUsers.push({ ...user });

                    }
                });
            } else {
                console.log(`User ${user.email} has no purchasedCourses property or it's not an array.`);
            }
        });

      
        console.log("Final currentAccessUsers:", currentUsers);
        console.log("Final expiredAccessUsers:", expiredUsers);

        setCurrentAccessUsers(currentUsers);
        setExpiredAccessUsers(expiredUsers);
    };



    // Filtering logic
    const filteredUsers = useMemo(() => {
        return users.filter((user) => {
            return (
                user.email.toLowerCase().includes(filterConfig.email.toLowerCase()) &&
                user.displayName.toLowerCase().includes(filterConfig.displayName.toLowerCase())
            );
        });
    }, [users, filterConfig]);

    // Sorting logic for Role View
    const sortedUsers = useMemo(() => {
        let sortableUsers = [...filteredUsers];
        if (sortConfig.key) {
            sortableUsers.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'asc' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'asc' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableUsers;
    }, [filteredUsers, sortConfig]);

    const handleUsersPerPageChange = (setPerPage, setPage) => (e) => {
        setPerPage(parseInt(e.target.value, 10));
        setPage(1); // Reset to the first page
    };

    const getCurrentTableData = (users, currentPage, usersPerPage) => {
        const startIndex = (currentPage - 1) * usersPerPage;
        const endIndex = startIndex + usersPerPage;
        return users.slice(startIndex, endIndex);
    };

    const requestSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    const handleFilterChange = (key, value) => {
        setFilterConfig((prevConfig) => ({
            ...prevConfig,
            [key]: value,
        }));
    };

    const handleViewDetails = (user) => {
        setSelectedUser(user);
        setTimeout(() => {
            userDetailRef.current.scrollIntoView({ behavior: 'smooth' });
        }, 100);
    };

    const handleBackToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setSelectedUser(null); // Optionally reset the selected user
    };

    // CSV export function (includes course-specific expiration date)
    const handleExportCsv = (users, filename) => {
        const csvData = users.map((user) => ({
            email: user.email,
            displayName: user.displayName,
            role: user.role || 'N/A',
            expirationDate: user.expirationDate ? user.expirationDate.toISOString() : 'N/A',
        }));
        exportToCsv(filename, csvData);
    };

    const renderTable = (
        title,
        users,
        currentPage,
        setPage,
        usersPerPage,
        setUsersPerPage
    ) => {
        const totalPages = Math.ceil(users.length / usersPerPage);

        return (
            <div className="user-role-section">
                <h3>{title}</h3>
                <table className="user-table">
                    <thead>
                        <tr>
                            <th>
                                <input
                                    type="text"
                                    placeholder="Filter by email"
                                    value={filterConfig.email}
                                    onChange={(e) => handleFilterChange('email', e.target.value)}
                                />
                                <button onClick={() => requestSort('email')}>Email</button>
                            </th>
                            <th>
                                <input
                                    type="text"
                                    placeholder="Filter by name"
                                    value={filterConfig.displayName}
                                    onChange={(e) => handleFilterChange('displayName', e.target.value)}
                                />
                                <button onClick={() => requestSort('displayName')}>Name</button>
                            </th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {getCurrentTableData(users, currentPage, usersPerPage).map((user) => (
                            <tr key={user.id}>
                                <td>{user.email}</td>
                                <td>{user.displayName}</td>
                                <td>
                                    <button onClick={() => handleViewDetails(user)}>View Details</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <div className="pagination-controls">
                    <label htmlFor={`usersPerPage-${title}`}>Users per page:</label>
                    <select
                        id={`usersPerPage-${title}`}
                        value={usersPerPage}
                        onChange={handleUsersPerPageChange(setUsersPerPage, setPage)}
                    >
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                    </select>

                    <div className="pagination-buttons">
                        <button onClick={() => setPage(currentPage - 1)} disabled={currentPage === 1}>
                            Previous
                        </button>
                        <span>
                            Page {currentPage} of {totalPages}
                        </span>
                        <button
                            onClick={() => setPage(currentPage + 1)}
                            disabled={currentPage === totalPages}
                        >
                            Next
                        </button>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="user-management">
            <h2 className="user-management-title">User Management</h2>

            {/* View Toggle */}
            <div className="view-toggle">
                <button
                    onClick={() => setViewMode('role')}
                    className={viewMode === 'role' ? 'active' : ''}
                >
                    Role View
                </button>
                <button
                    onClick={() => setViewMode('course')}
                    className={viewMode === 'course' ? 'active' : ''}
                >
                    Course View
                </button>
            </div>

            {viewMode === 'role' ? (
                <>
                    {/* Role View */}
                    {renderTable(
                        'Admins',
                        getCurrentTableData(
                            sortedUsers.filter((user) => user.role === 'admin'),
                            adminPage,
                            adminsPerPage
                        ),
                        adminPage,
                        setAdminPage,
                        adminsPerPage,
                        setAdminsPerPage
                    )}
                    {renderTable(
                        'Instructors',
                        getCurrentTableData(
                            sortedUsers.filter((user) => user.role === 'instructor'),
                            instructorPage,
                            instructorsPerPage
                        ),
                        instructorPage,
                        setInstructorPage,
                        instructorsPerPage
                    )}
                    {renderTable(
                        'Users',
                        getCurrentTableData(
                            sortedUsers.filter((user) => user.role === 'user'),
                            userPage,
                            usersPerPage
                        ),
                        userPage,
                        setUserPage,
                        usersPerPage,
                        setUsersPerPage
                    )}
                </>
            ) : (
                <>
                    {/* Course View */}
                    <div className="course-view">
                        <h3>Course View</h3>
                        <label htmlFor="courseSelect">Select Course:</label>
                        <select
                            id="courseSelect"
                            value={selectedCourse || ''}
                            onChange={(e) => {
                                setSelectedCourse(e.target.value);
                                filterUsersByCourse(e.target.value);
                            }}
                        >
                            <option value="">Select a Course</option>
                            {courses.map((course) => (
                                <option key={course.id} value={course.id}>
                                    {course.title || course.name}
                                </option>
                            ))}
                        </select>

                        {currentAccessUsers.length > 0 || expiredAccessUsers.length > 0 ? (
                            <>
                                {/* Export CSV Button - Only in Course View */}
                                <div className="csv-export-buttons">
                                    <button
                                        className="export-button"
                                        onClick={() =>
                                            handleExportCsv(
                                                [...currentAccessUsers, ...expiredAccessUsers],
                                                `CourseView-${selectedCourse}.csv`
                                            )
                                        }
                                    >
                                        Export Course View as CSV
                                    </button>
                                </div>

                                <div className="course-users-section">
                                    {renderTable(
                                        'Current Access',
                                        currentAccessUsers,
                                        userPage,
                                        setUserPage,
                                        usersPerPage,
                                        setUsersPerPage
                                    )}

                                    {renderTable(
                                        'Expired Access',
                                        expiredAccessUsers,
                                        userPage,
                                        setUserPage,
                                        usersPerPage,
                                        setUsersPerPage
                                    )}
                                </div>
                            </>
                        ) : (
                            <div>No users found for this course</div>
                        )}
                    </div>
                </>
            )}

            {selectedUser && (
                <>
                    <div ref={userDetailRef}>
                        <UserDetail user={selectedUser} />
                    </div>
                    <button className="back-to-top" onClick={handleBackToTop}>
                        Back to Top
                    </button>
                </>
            )}
        </div>
    );
};

export default UserManagement;
