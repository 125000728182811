import React, { useState } from 'react';
import useFetchCoursesByTemplate from '../hooks/useFetchCoursesByTemplate';
import CourseDetails from './CourseDetails';
import './CourseList.css';

const CourseList = ({ template }) => {
    const { activeCourses, expiredCourses, loading, error } = useFetchCoursesByTemplate(template.id);

    // Instead of storing the entire selected course object,
    // we only store the selectedCourseId to pass to CourseDetails.
    const [selectedCourseId, setSelectedCourseId] = useState(null);

    const handleCourseClick = (course) => {
        // Pass the ID of the selected course
        setSelectedCourseId(course.id);
    };

    // Format Firestore date or a normal date string
    const formatDate = (date) => {
        if (!date) return 'N/A';

        // If Firestore Timestamp-like
        if (date._seconds) {
            return new Date(date._seconds * 1000).toLocaleDateString();
        }
        // Otherwise assume it's a string or Date
        return new Date(date).toLocaleDateString();
    };

    if (loading) {
        return <div>Loading courses...</div>;
    }

    if (error) {
        console.error('Error in CourseList:', error);
        return <div>Error loading courses: {error.message}</div>;
    }

    return (
        <div className="course-list">
            <h3>Courses for Template: {template.name}</h3>

            {/* If we have a selectedCourseId, show CourseDetails */}
            {selectedCourseId ? (
                <CourseDetails
                    courseId={selectedCourseId}
                    onClose={() => setSelectedCourseId(null)}
                />
            ) : (
                <>
                    <h4>Active Courses</h4>
                    {activeCourses && activeCourses.length > 0 ? (
                        <table className="course-table">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Description</th>
                                    <th>Start Date</th>
                                    <th>End Date</th>
                                    <th>Code</th>
                                    <th>Price</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {activeCourses.map((course) => (
                                    <tr key={course.id}>
                                        <td>{course.name || 'Unnamed Course'}</td>
                                        <td>{course.description || 'No description available'}</td>
                                        <td>{formatDate(course.startDate)}</td>
                                        <td>{formatDate(course.endDate)}</td>
                                        <td>{course.courseCode || 'N/A'}</td>
                                        <td>${course.coursePrice?.toFixed(2) || '0.00'}</td>
                                        <td>
                                            <button onClick={() => handleCourseClick(course)}>
                                                View Details
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : (
                        <p>No active courses available.</p>
                    )}

                    <h4>Expired Courses</h4>
                    {expiredCourses && expiredCourses.length > 0 ? (
                        <table className="course-table">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Description</th>
                                    <th>Start Date</th>
                                    <th>End Date</th>
                                    <th>Code</th>
                                    <th>Price</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {expiredCourses.map((course) => (
                                    <tr key={course.id}>
                                        <td>{course.name || 'Unnamed Course'}</td>
                                        <td>{course.description || 'No description available'}</td>
                                        <td>{formatDate(course.startDate)}</td>
                                        <td>{formatDate(course.endDate)}</td>
                                        <td>{course.courseCode || 'N/A'}</td>
                                        <td>${course.coursePrice?.toFixed(2) || '0.00'}</td>
                                        <td>
                                            <button onClick={() => handleCourseClick(course)}>
                                                View Details
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : (
                        <p>No expired courses available.</p>
                    )}
                </>
            )}
        </div>
    );
};

export default CourseList;
