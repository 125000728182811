// hooks/useCreateCourse.js
import { useState } from 'react';
import customAxios from '../customAxios';

const useCreateCourse = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);

    const createCourse = async (courseData) => {
        setLoading(true);
        setError(null);
        setSuccess(false);

        try {
            const response = await customAxios.post(
                `${process.env.REACT_APP_API_BASE_URL}/courses`,
                courseData
            );
            if (response.status === 200 || response.status === 201) {
                setSuccess(true);
            }
        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    return { createCourse, loading, error, success };
};

export default useCreateCourse;
